import { apiSlice } from "../apiSlice";
const headers = { Modulecima: "dashboax" };
const prefix = "/Dashboax/graficxx/tareasxx";
export const graficsxApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getGraphs: builder.query({
      query: ({ USRIDXXX, CARIDXXX }) => ({
        url: `${prefix}/getGraphicsByUser/${USRIDXXX}/${CARIDXXX}`,
        method: "GET",
        headers,
      }),
    }),

    getDataGraph: builder.mutation({
      query: ({ filters, USRIDXXX, CARIDXXX }) => ({
        url: `${prefix}/getGraphicDataByUser/${USRIDXXX}/${CARIDXXX}`,
        method: "GET",
        params: { ...filters },
        headers,
      }),
    }),

    getClasificationFilter: builder.query({
      query: ({ CARIDXXX, USRIDXXX }) => ({
        url: `${prefix}/getClasificationFilter/${USRIDXXX}/${CARIDXXX}`,
        method: "GET",
        headers,
      }),
    }),

    getResponsiblesFilter: builder.mutation({
      query: ({ CARIDXXX, USRIDXXX, CLATARID, filters }) => ({
        url: `${prefix}/getResponsibleFilter/${USRIDXXX}/${CARIDXXX}/${CLATARID || ""}`,
        method: "GET",
        headers,
        params: { ...filters },
      }),
    }),
  }),
});

export const {
  useGetGraphsQuery,
  useGetDataGraphMutation,
  useGetClasificationFilterQuery,
  useGetResponsiblesFilterMutation,
} = graficsxApiSlice;

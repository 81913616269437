/**
 * ApiSlice encargado de gestionar los EndPoint para mi perfil pantalla de cominuciones
 * @author Sebastian Rojas <sebastian.rojas@repremundo.com.co>
 */
import { apiSlice } from "../apiSlice";

export const comunicxApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAdvertisements: builder.query({
      query: () => ({
        url: `/Parmpoxx/Comunicx/Advertxx`,
      }),
    }),
    getChannels: builder.query({
      query: () => ({
        url: `/Parmpoxx/Comunicx/Channels`,
      }),
    }),
    getAdvertisementsChannelsbyUser: builder.query({
      query: (USRIDXXX) => ({
        url: `/Parmpoxx/Comunicx/` + USRIDXXX,
      }),
      providesTags: ["Create"],
    }),
    createAdvertisementsChannelsbyUser: builder.mutation({
      query: (parameters) => ({
        url: `/Parmpoxx/Comunicx`,
        method: "POST",
        body: {
          ...parameters,
        },
      }),
      invalidatesTags: ["Create"],
    }),
    deleteAdvertisementsChannelsbyUser: builder.mutation({
      query: (USRIDXXX) => ({
        url: `/Parmpoxx/Comunicx/` + USRIDXXX,
        method: "DELETE",
      }),
      invalidatesTags: ["Create"],
    }),
  }),
});

export const { useGetAdvertisementsQuery, useGetChannelsQuery, useGetAdvertisementsChannelsbyUserQuery } =
  comunicxApiSlice;
export const { useCreateAdvertisementsChannelsbyUserMutation, useDeleteAdvertisementsChannelsbyUserMutation } =
  comunicxApiSlice;

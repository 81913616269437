import { apiSlice } from "../apiSlice";

export const devicesxApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSesionsDevices: builder.query({
      query: (parameters) => ({
        url: `/Parmpoxx/Devicesx`,
        params: { "filters[tokenable_id]": parameters.user,
        "filters[REGESTXX]": "ACTIVO",
        "filters[name]": "CIMA",
        "filters[USERAGEN][NOTIN]": "",
        "sorts": "-last_used_at",
       },
      }),
    }),
    inactiveSesionDevice: builder.mutation({
      query: (parameters) => ({
        url: `/Parmpoxx/Devicesx`,
        method: "DELETE",
        body: parameters,
      }),
    })
  })
});

export const {
  useLazyGetSesionsDevicesQuery,
  useInactiveSesionDeviceMutation
} = devicesxApiSlice;

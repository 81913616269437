import { apiSlice } from "../../apiSlice";
export const solicupoApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createSolicupo: builder.mutation({
      query: (parameters) => ({
        url: `/Autoriza/Solicupo`,
        method: "POST",
        body: parameters,
      }),
    }),
  }),
});

export const {
  useCreateSolicupoMutation,
} = solicupoApiSlice;

import {
  Box,
  Button,
  Flexbox,
  Heading,
  Modal,
  ModalBody,
  ModalFocusContainer,
  ModalFooter,
  ModalHeader,
  shootToast,
} from "codekit";

import { useForm, FormProvider } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useCreateAdvanceKeyResultExpoMutation,
  useCreateAdvanceKeyResultMutation,
  useCreateAdvanceKeyResultRegistMutation,
  useCreateAdvanceMutation,
  useGetDataAdvanceRCMutation,
} from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoApiSlice";
import {
  getTotalGeneral,
  resetState,
  setMasiveValueTribute,
  setMasiveValueUsd,
} from "../../../../libs/redux/slices/Modimpxx/Anticipo/anticipoSlice";

import { getDataUser } from "../../../../libs/redux/slices/authSlice/authSlice";
import Resendxx from "./Resendxx";
import {
  setValuesINGREPRO,
  setValuesPAGOSTER,
  setValuesVALUETBT,
  setValuesVALUEUSD,
  valuesCif,
  valuesCONTACTX,
  valuesINGREPRO,
  valuesPAGOSTER,
  valuesUsd,
} from "./utils/defValue";
import Antcform from "./Antcform";

//Valores por defecto del formulario
const defaultValues = {
  VALUEUSD: [valuesUsd],
  VALUETBT: [valuesCif],
  PAGOSTER: [valuesPAGOSTER],
  INGREPRO: [valuesINGREPRO],
  ANTTCIFX: 0,
  CONTACTX: [valuesCONTACTX],
  ANTTBDIX: "NO",
  ANTTDIMX: "NO",
  ANTVDIMX: "NO",
  ANTVLDIX: "NO",
  ANTEVLRX: 0,
  ANTTOTAL: 0,
  CLIIDXXX: "",
  DOCNROXX: "",
};

/**
 * Componente padre Anticipo Cliente
 * @returns Component
 */
const Anticipo = ({ setIsAnticipo, isAnticipoFromAntiprov, data = null, isKeyResult = false, isVisualize }) => {
  const dispatch = useDispatch();
  const infoUser = useSelector(getDataUser);
  const [getDataAdvanceRC] = useGetDataAdvanceRCMutation();
  const methods = useForm({
    mode: "onBlur",
    defaultValues: () => handleGetDataForm(),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const totalGeneral = useSelector(getTotalGeneral);
  const [createAdvance] = useCreateAdvanceMutation();
  const [dataClient, setDataClient] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [order, setOrder] = useState("");
  const [dataError, setDataError] = useState([]);
  const [createAdvanceKeyResult] = useCreateAdvanceKeyResultMutation();
  const [createAdvanceKeyResultRegist] = useCreateAdvanceKeyResultRegistMutation();
  const [createAdvanceKeyResultExpo] = useCreateAdvanceKeyResultExpoMutation();
  const functionsKeyResult = {
    createAdvance,
    createAdvanceKeyResult,
    createAdvanceKeyResultRegist,
    createAdvanceKeyResultExpo,
  };

  const hasError = Object.keys(errors).length;
  const isFormValid = !hasError;

  //Retorna los datos por default del formulario
  const handleGetDataForm = async () => {
    if (!isKeyResult) {
      return defaultValues;
    } else {
      //Llamado al api para traer los datos
      return await getDataAdvanceRC({ DOCNROXX: data.DOCNROXX })
        .unwrap()
        .then((response) => {
          const { data } = response;
          let countData = typeof data === "object" ? Object.keys(data).length : data.length;
          if (countData > 0) {
            const { ANTCYYYY, VALUEUSD, VALUETBT, PAGOSTER, INGREPRO, CONTACTX } = data;
            handleSetTotalUsd(VALUEUSD);
            handleSetMasiveValueUsd(VALUETBT);
            return {
              ...ANTCYYYY,
              VALUEUSD: setValuesVALUEUSD(VALUEUSD),
              VALUETBT: setValuesVALUETBT(VALUETBT),
              PAGOSTER: setValuesPAGOSTER(PAGOSTER),
              INGREPRO: setValuesINGREPRO(INGREPRO),
              CONTACTX,
            };
          } else {
            return {
              ...defaultValues,
              DOCNROXX: data.DOCNROXX,
              CLIIDXXX: `${data.CLIIDXXX} - ${data.CLINOMXX}`,
            };
          }
        })
        .catch();
    }
  };

  //Setear masivamente los valores de la seccion Valor en Aduanas USD
  const handleSetTotalUsd = (data) => {
    dispatch(setMasiveValueUsd(setValuesVALUEUSD(data)));
  };

  //Setear masivamente los valores de la seccion Valor de los Tributos
  const handleSetMasiveValueUsd = (data) => {
    dispatch(setMasiveValueTribute(setValuesVALUETBT(data)));
  };

  //Guardado del formulario
  const onSubmit = async (formData) => {
    const newData = { ...formData, CLIIDXXX: formData.CLIIDXXX.split(" - ")[0], CLINOMXX: dataClient.CLINOMXX };
    const { ANTEMAIL, CONTACTX } = formData;
    const filterContact = CONTACTX.filter((item) => item.CONTACID !== "");
    if (filterContact.length === 0 && ANTEMAIL === "") {
      shootToast({
        variant: "error",
        text: "Debe ingresar mínimo un correo",
        autoHide: true,
      });
    } else {
      let functionSend = "createAdvance";
      setIsCreating(true);
      if (!isKeyResult) {
        newData.KEYRESUL = "NO";
      } else {
        //Complemento data si es Resultado Clave
        newData.KEYRESUL = "SI";
        newData.OBJIDXXX = data.OBJIDXXX;
        newData.OBJESPID = data.OBJESPID;
        newData.RESCLAXX = data.RESCLAXX;
        newData.OBJIDHIX = data.OBJIDHIX;
        newData.RESOBJXX = "COMPLETADO";
        switch (data.OBJIDHIX) {
          case 100:
            functionSend = "createAdvanceKeyResult";
            break;
          case 103:
            functionSend = "createAdvanceKeyResultRegist";
            break;
          case 105:
            functionSend = "createAdvanceKeyResultExpo";
            break;
          default:
            functionSend = "createAdvance";
            break;
        }
      }
      if (functionsKeyResult[functionSend]) {
        await functionsKeyResult[functionSend](newData)
          .unwrap()
          .then(() => {
            setIsAnticipo(false);
            dispatch(resetState());
          })
          .catch(() => {});
      }
    }
  };

  useEffect(() => {
    setValue("ANTTOTAL", totalGeneral);
  }, [totalGeneral, setValue]);

  return !isKeyResult ? (
    <Modal size="xl" isOpen={true}>
      <ModalHeader>
        <Flexbox justifyContent="between" alignItems="center">
          <Heading variant="heading-6">Solicitud de Anticipo</Heading>
          <Button
            variant="secondary"
            icon="close"
            onlyIcon
            size="m"
            onClick={() => {
              setIsAnticipo(false);
              dispatch(resetState());
            }}
          />
        </Flexbox>
      </ModalHeader>
      <FormProvider {...methods}>
        <form id="anticipo" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody size="sm">
            {order !== "" && (
              <Resendxx
                dataError={dataError}
                setDataError={setDataError}
                infoUser={infoUser}
                setIsAnticipo={setIsAnticipo}
              />
            )}
            <Antcform
              data={data}
              setDataClient={setDataClient}
              setOrder={setOrder}
              setDataError={setDataError}
              isVisualize={isVisualize}
            />
          </ModalBody>
          <ModalFooter>
            <Flexbox justifyContent="between" alignItems="center" gap={4}>
              <Button
                type="button"
                variant="secondary"
                size="2l"
                minWidth={200}
                onClick={() => {
                  setIsAnticipo(false);
                  dispatch(resetState());
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary-bold"
                size="2l"
                minWidth={200}
                isLoading={isCreating}
                disabled={!isFormValid}
              >
                Crear
              </Button>
            </Flexbox>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  ) : (
    <FormProvider {...methods}>
      <form id="anticipo" onSubmit={handleSubmit(onSubmit)}>
        <ModalFocusContainer>
          <Antcform
            data={data}
            setDataClient={setDataClient}
            setOrder={setOrder}
            setDataError={setDataError}
            isKeyResult={isKeyResult}
            isVisualize={isVisualize}
          />
        </ModalFocusContainer>
        <Box spacingX={4} spacingY={8} borderTop={1} borderColor="neutral-300">
          <ModalFocusContainer>
            <Flexbox justifyContent="between" alignItems="center" gap={4}>
              <>
                <Button type="button" variant="secondary" size="2l" minWidth={200} onClick={() => setIsAnticipo()}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary-bold"
                  size="2l"
                  isLoading={isCreating}
                  disabled={errors?.REQINSPR || isVisualize}
                  minWidth={200}
                >
                  Guardar
                </Button>
              </>
            </Flexbox>
          </ModalFocusContainer>
        </Box>
      </form>
    </FormProvider>
  );
};

export default Anticipo;

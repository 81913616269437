import { apiSlice } from "../../apiSlice";
export const anticipoApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getDataDim: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getDataDim`,
        method: "GET",
        params: { "filters[DOIIDXXX]": parameters.DOCNROXX },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getConcepts: builder.query({
      query: () => ({
        url: `/Modimpxx/Anticipo/getConcepts`,
        method: "GET",
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getOwnConcepts: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getOwnConcepts`,
        method: "GET",
        params: { filter: parameters.filter },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getTRM: builder.query({
      query: () => ({
        url: `/Modimpxx/Anticipo/getTRM`,
        method: "GET",
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getContactAdvance: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getContactAdvance`,
        method: "GET",
        params: { ...parameters },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    createAdvance: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo`,
        method: "POST",
        body: parameters,
        headers: { modulecima: "modimpxx" },
      }),
    }),
    createAdvanceKeyResult: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo`,
        method: "POST",
        body: parameters,
        headers: { modulecima: "modimpxx" },
      }),
      invalidatesTags: ["keyResultImpo"],
    }),
    sendEmailAdvance: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/sendEmail`,
        method: "POST",
        body: parameters,
        headers: { modulecima: "modimpxx" },
      }),
    }),
    notificationGenio: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/notificationGenio`,
        method: "POST",
        body: parameters,
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getConsecutiveSLB: builder.query({
      query: () => ({
        url: `/Modimpxx/Anticipo/getConsecutiveSLB`,
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getAdvanceWithErrors: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getAdvanceWithErrors`,
        method: "GET",
        params: { ...parameters },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getAdvanceWithErrorsQ: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getAdvanceWithErrors`,
        method: "GET",
        params: { ...parameters },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getDataAdvance: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getDataAdvance`,
        method: "GET",
        params: { ...parameters },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getAutoClient: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getClientById`,
        method: "GET",
        params: { filter: parameters.filter },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getAutoClientQuery: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getClientById`,
        method: "GET",
        params: { filter: parameters.filter },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getClientByOrder: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getClientByOrder`,
        method: "GET",
        params: { ...parameters },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getClientByFact: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getClientByFact`,
        method: "GET",
        params: { ...parameters },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getClientByData: builder.query({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getClientByData`,
        method: "GET",
        params: {
          "filters[ANTCYYYY.CLIIDXXX]": parameters.CLIIDXXX,
          "filters[ANTCYYYY.ESTADOTA]": "ENVIADO",
        },
        headers: { modulecima: "modimpxx" },
      }),
    }),
    getDataAdvanceRC: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/getDataAvance/${parameters.DOCNROXX}`,
        method: "GET",
        headers: { modulecima: "modimpxx" },
      }),
    }),
    createAdvanceKeyResultRegist: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo`,
        method: "POST",
        body: parameters,
        headers: { modulecima: "modimpxx" },
      }),
      invalidatesTags: ["KeyResults"],
    }),
    generatePdfAdvance: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo/generatePdfAdvance`,
        method: "GET",
        params: parameters,
        headers: { modulecima: "modimpxx" },
      }),
    }),
    createAdvanceKeyResultExpo: builder.mutation({
      query: (parameters) => ({
        url: `/Modimpxx/Anticipo`,
        method: "POST",
        body: parameters,
        headers: { modulecima: "modimpxx" },
      }),
      invalidatesTags: ["KeyResults"],
    }),
  }),
});

export const {
  useLazyGetDataDimQuery,
  useGetConceptsQuery,
  useGetOwnConceptsMutation,
  useGetTRMQuery,
  useGetContactAdvanceQuery,
  useLazyGetContactAdvanceQuery,
  useCreateAdvanceMutation,
  useSendEmailAdvanceMutation,
  useNotificationGenioMutation,
  useLazyGetConsecutiveSLBQuery,
  useLazyGetDataAdvanceQuery,
  useGetAdvanceWithErrorsMutation,
  useGetAutoClientMutation,
  useLazyGetAdvanceWithErrorsQQuery,
  useLazyGetAutoClientQueryQuery,
  useLazyGetClientByOrderQuery,
  useLazyGetClientByFactQuery,
  useLazyGetClientByDataQuery,
  useGetDataAdvanceRCMutation,
  useCreateAdvanceKeyResultMutation,
  useCreateAdvanceKeyResultRegistMutation,
  useGeneratePdfAdvanceMutation,
  useCreateAdvanceKeyResultExpoMutation,
} = anticipoApiSlice;

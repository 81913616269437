/**
 * Gestionar los endpoint Notificaciones Usuario
 * @author Oscar Rosero
 */

import { apiSlice } from "../apiSlice";

export const notificxApliSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getQuantity: builder.query({
      query: () => ({
        url: `/Parmpoxx/Notificx/getQuantity`,
      }),
    }),
    getNotificationUser: builder.query({
      query: (params) => ({
        url: `/Parmpoxx/Notificx/getNotificationUser?page=${params.page}&per_page=15`,
        params: {
          sorts: "-NOTIDXXX",
          filters: { ...params.filters },
        },
      }),
    }),
    getPollingNotificationUser: builder.query({
      query: (params) => ({
        url: `/Parmpoxx/Notificx/getNotificationUser?page=1&per_page=15`,
        params: { sorts: "-NOTIDXXX", filters: { ...params.filters } },
      }),
    }),
    getPollingGeneralNotificationUser: builder.query({
      query: (params) => ({
        url: `/Parmpoxx/Notificx/getNotificationUser?page=1&per_page=15`,
        params: { sorts: "-NOTIDXXX", filters: { ...params.filters } },
      }),
    }),
    getNotificationsRead: builder.query({
      query: () => ({
        url: `/Parmpoxx/Notificx/getNotificationsRead`,
      }),
      providesTags: ["Notification"],
    }),
    markAsRead: builder.mutation({
      query: (parameters) => ({
        url: `/Parmpoxx/Notificx/${parameters.NOTIDXXX}`,
        method: "PUT",
        body: parameters,
      }),
    }),
    markAsReadMasive: builder.mutation({
      query: (parameters) => ({
        url: `/Parmpoxx/Notificx`,
        method: "PUT",
        body: parameters,
      }),
    }),
  }),
});

export const {
  useGetQuantityQuery,
  useLazyGetNotificationUserQuery,
  useLazyGetNotificationsReadQuery,
  useMarkAsReadMutation,
  useMarkAsReadMasiveMutation,
  useGetPollingNotificationUserQuery,
  useGetPollingGeneralNotificationUserQuery,
  useGetNotificationsReadQuery,
} = notificxApliSlice;

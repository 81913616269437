import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider, Box, Checkbox, Body, TableLoader, Flexbox, Button } from "codekit";
import { getMenuUser, getDataUser } from "../../libs/redux/slices/authSlice/authSlice";
import {
  useGetMyPreferencesByUserQuery,
  useCreateOrUpdateMyPreferencesMutation,
} from "../../libs/redux/slices/MyprefexSlice/myprefexApiSlice";
import { useForm, useFieldArray } from "react-hook-form";

const Myprefex = () => {
  const { control, register, handleSubmit } = useForm();
  const dataMenu = useSelector(getMenuUser);
  const userData = useSelector(getDataUser);
  const {
    fields: gridCIMS0075,
    append: appendGridCIMS0075,
    update: updateGridCIMS0075,
  } = useFieldArray({
    control,
    name: "CIMS0075",
  });
  const { data, isSuccess } = useGetMyPreferencesByUserQuery(userData.USRIDXXX);
  const [createOrUpdatePreferences, { isLoading }] = useCreateOrUpdateMyPreferencesMutation();

  useEffect(() => {
    if (isSuccess) {
      const getUSRBANXX = (find) => {
        let found = data?.data?.filter(
          (element) =>
            find.MODIDXXX === element.MODIDXXX &&
            find.PROIDXXX === element.PROIDXXX &&
            find.MENIDXXX === element.MENIDXXX
        );
        return typeof found[0]?.USRBANXX !== "undefined" ? found[0].USRBANXX : "";
      };
      dataMenu.forEach((module) => {
        module.CIMS0004.forEach((process) => {
          process.CIMS0005.forEach((optionMenu) => {
            if (optionMenu.MENAPLBA === "SI") {
              let optionSelected = {
                MODIDXXX: module.MODIDXXX,
                MODDESXX: module.MODDESXX,
                PROIDXXX: process.PROIDXXX,
                PRODESXX: process.PRODESXX,
                MENIDXXX: optionMenu.MENIDXXX,
                OPCDESXX: optionMenu.OPCDESXX,
              };
              optionSelected = { ...optionSelected, USRBANXX: getUSRBANXX(optionSelected) };
              optionSelected = { ...optionSelected, isChecked: optionSelected.USRBANXX === "SI" ? true : false };
              appendGridCIMS0075(optionSelected);
            }
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onSubmit = async (PreFormData) => {
    let formData = {};

    formData.CIMS0075 = PreFormData.CIMS0075.map((item) => {
      let preItem = { ...item };
      if (preItem.isChecked === false && preItem.USRBANXX === "") {
        preItem.USRBANXX = "NO";
      }
      return preItem;
    });
    await createOrUpdatePreferences(formData).unwrap().then().catch();
  };
  return isSuccess ? (
    <Box mt={7} mb={7}>
      <Divider text="Configuración Banner Flotante" color="accent-600" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box border={1} mt={5} mb={3}>
          <form onSubmit={handleSubmit(onSubmit)}></form>
          {gridCIMS0075.map((CIMS0075, index) => {
            return (
              <Box key={CIMS0075.id} mt={5} mb={5} spacingX={6}>
                <Divider text={`${CIMS0075.OPCDESXX}`} color="primary-950">
                  <Checkbox
                    {...register(`CIMS0075[${index}][isChecked]`)}
                    type="switch"
                    checked={CIMS0075.isChecked}
                    onClick={(event) => {
                      let preCIMS0075 = { ...CIMS0075 };
                      preCIMS0075.isChecked = event.target.checked;
                      preCIMS0075.USRBANXX = preCIMS0075.isChecked ? "SI" : "NO";
                      updateGridCIMS0075(index, preCIMS0075);
                    }}
                  />
                </Divider>
                <br />
                <Body variant="body-3">Módulo: {`${CIMS0075.MODDESXX}`}</Body>
                <Body variant="body-3">Proceso: {`${CIMS0075.PRODESXX}`}</Body>
              </Box>
            );
          })}
        </Box>
        <Flexbox justifyContent="end">
          <Button type="submit" variant="primary-bold" isLoading={isLoading}>
            Guardar
          </Button>
        </Flexbox>
      </form>
    </Box>
  ) : (
    <TableLoader />
  );
};

export default Myprefex;

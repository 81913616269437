import { apiSlice } from "../apiSlice";

export const myprefexApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMyPreferencesByUser: builder.query({
      query: (USRIDXXX) => ({
        url: `/Parmpoxx/Myprefex/` + USRIDXXX,
      }),
      providesTags: ["Create"],
    }),
    createOrUpdateMyPreferences: builder.mutation({
      query: (parameters) => ({
        url: `/Parmpoxx/Myprefex`,
        method: "POST",
        body: {
          ...parameters,
        },
      }),
      invalidatesTags: ["Create"],
    }),
  }),
});

export const { useGetMyPreferencesByUserQuery, useCreateOrUpdateMyPreferencesMutation } = myprefexApiSlice;

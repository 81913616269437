import { apiSlice } from "../../slices/apiSlice";

const headers = { Modulecima: "Financix" };

export const cupodispApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getCurrentQuotaByClient: builder.mutation({
      query: (parameters) => ({
        url: `/Financix/Antiprov/getAvailableQuota/${parameters.CLIIDXXX}`,
        method: "GET",
        headers,
      }),
    })
  }),
});

export const {
  useGetCurrentQuotaByClientMutation
} = cupodispApiSlice;
